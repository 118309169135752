import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/forge/ra.fakel.com.ua/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "комопненты-inputs"
    }}>{`Комопненты `}<inlineCode parentName="h1">{`<Inputs>`}</inlineCode></h1>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`<AutoCompleteInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<CodeInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<DateInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<FileInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<GalleryInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<NumberInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<PasswordInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<RadioGroupInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<SelectInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<SwitchInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<TextInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<TranslatableInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<ReferenceInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<ReferenceManyInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<ReferenceArrayInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<Input>`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "props"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "базовые-input-компоненты"
    }}>{`Базовые `}<inlineCode parentName="h2">{`<Input>`}</inlineCode>{` компоненты`}</h2>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`<AutoCompleteInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<CodeInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<DateInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<FileInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<GalleryInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<NumberInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<PasswordInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<RadioGroupInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<SelectInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<SwitchInput>`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`<TextInput>`}</inlineCode></li>
    </ul>
    <h3 {...{
      "id": "пример-использования"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`<Create>
    {(createProps) => (
        <SimpleForm
            {...createProps}
            initialValue={{
                title: '',
                body: '',
                date: '',
            }}
        >
            <Space direction="vertical">
                <TextInput label="Title" name="title" placeholder="Enter title" />
                <TextInput label="Body" name="body" placeholder="Enter body" />
                <DateInput name="date" />
            </Space>
        </SimpleForm>
    )}
</Create>
`}</code></pre>
    <h2 {...{
      "id": "selectinput"
    }}>{`SelectInput`}</h2>
    <p>{`Пользователь может не передавать `}<inlineCode parentName="p">{`options`}</inlineCode>{`, в этом случаи `}<inlineCode parentName="p">{`options`}</inlineCode>{` будут вычислятся из данных, конкретного ресурса.`}</p>
    <p>{`Для этого нужно передать два параметра:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`valuePropName`}</inlineCode></strong>{` - ключ, по которому будут выбрано значение из записи и будет использоватся как значение для `}<inlineCode parentName="p">{`<option>`}</inlineCode></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`titlePropName`}</inlineCode></strong>{` - ключ, по которому будут выбрано значение из записи и будет использоватся как `}<inlineCode parentName="p">{`label`}</inlineCode>{` для `}<inlineCode parentName="p">{`<option>`}</inlineCode></p>
      </li>
    </ul>
    <h3 {...{
      "id": "пример-использования-1"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`<Create>
    {(createProps) => (
        <SimpleForm
            {...createProps}
            initialValue={{
                author: '',
            }}
        >
            <Space direction="vertical">
                <SelectInput name="author" valuePropName="_id" titlePropName="username" />
            </Space>
        </SimpleForm>
    )}
</Create>
`}</code></pre>
    <p>{`Также можно передать `}<inlineCode parentName="p">{`<options>`}</inlineCode>{`, которые будут использоватся в `}<inlineCode parentName="p">{`<select>`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "пример-использования-2"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`const options = [
    {value: "Value 1", title: "Title 1"},
    {value: "Value 2", title: "Title 2"},
    {value: "Value 3", title: "Title 3"},
]

<Create>
    {(createProps) => (
        <SimpleForm
            {...createProps}
            initialValue={{
                author: '',
            }}
        >
            <Space direction="vertical">
                <SelectInput name="author" options={options}/>
            </Space>
        </SimpleForm>
    )}
</Create>
`}</code></pre>
    <h2 {...{
      "id": "translatableinput"
    }}>{`TranslatableInput`}</h2>
    <h3 {...{
      "id": "props-1"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`locales (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`object`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(locale) => React Component`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования-3"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`<Create>
    {(createProps) => (
        <SimpleForm
            {...createProps}
            initialValue={{
                author: '',
            }}
        >
            <Space direction="vertical">
                <TranslatableInput locales={locales}>
                    {(locale) => {
                        return (
                            <TextInput
                                name={\`text.\${locale}\`}
                                label="Text"
                            />
                        );
                    }}
                </TranslatableInput>
            </Space>
        </SimpleForm>
    )}
</Create>
`}</code></pre>
    <h2 {...{
      "id": "referenceinput"
    }}>{`ReferenceInput`}</h2>
    <p>{`Компонент для получение одной записи из `}<inlineCode parentName="p">{`reference`}</inlineCode>{` ресурса.`}</p>
    <h3 {...{
      "id": "props-2"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`source (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`reference (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`React Component`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p"><inlineCode parentName="strong">{`reference`}</inlineCode></strong>{` - название ресурса, где нужно получить данные`}</p>
    <p><strong parentName="p"><inlineCode parentName="strong">{`source`}</inlineCode></strong>{` - ключ, по которому нужно выбрать данные из `}<strong parentName="p"><inlineCode parentName="strong">{`reference`}</inlineCode></strong></p>
    <p>{`Компонент `}<inlineCode parentName="p">{`<ReferenceInput>`}</inlineCode>{` использует `}<inlineCode parentName="p">{`dataProvider`}</inlineCode>{` для того чтобы отправить `}<inlineCode parentName="p">{`getOne`}</inlineCode>{` запрос с id. Полученные данные используються компонентом `}<inlineCode parentName="p">{`children`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "пример-использования-4"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`<Create>
    {(createProps) => (
        <SimpleForm
            {...createProps}
            initialValue={{
                name: '',
            }}
        >
            <Space direction="vertical">
                <ReferenceInput
                    source="userId"
                    reference="users"
                >
                    <TextInput
                        label="Author"
                        name="name"
                        placeholder="Enter author name"
                    />
                </ReferenceInput>
            </Space>
        </SimpleForm>
    )}
</Create>
`}</code></pre>
    <h2 {...{
      "id": "referencemanyinput"
    }}>{`ReferenceManyInput`}</h2>
    <p>{`Компонент для получение нескольких записей из `}<inlineCode parentName="p">{`reference`}</inlineCode>{` ресурса.`}</p>
    <h3 {...{
      "id": "пример-использования-5"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`<Create>
    {(createProps) => (
        <SimpleForm
            {...createProps}
            initialValue={{
                author: '',
            }}
        >
            <Space direction="vertical">
                <ReferenceManyInput name="author" reference="users" label="Author">
                    <SelectInput name="author" valuePropName="_id" titlePropName="username" />
                </ReferenceManyInput>
            </Space>
        </SimpleForm>
    )}
</Create>
`}</code></pre>
    <p>{`Для того чтобы использовать `}<inlineCode parentName="p">{`<ReferenceInput>`}</inlineCode>{` нужно определить `}<inlineCode parentName="p">{`getMany`}</inlineCode>{` метод в `}<inlineCode parentName="p">{`dataProvider`}</inlineCode>{`. Если его нет пользователь получит сообщение об ошибке.`}</p>
    <p>{`Компонент `}<inlineCode parentName="p">{`<ReferenceInput>`}</inlineCode>{` использует `}<inlineCode parentName="p">{`dataProvider`}</inlineCode>{` для того чтобы отправить `}<inlineCode parentName="p">{`getMany`}</inlineCode>{` запрос с id. Полученные данные используються компонентом `}<inlineCode parentName="p">{`children`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "arrayinput"
    }}>{`ArrayInput`}</h2>
    <p>{`Компонент для управления массивом данных - добавлять, удалять, изменять позицию в массиве.`}</p>
    <h3 {...{
      "id": "props-3"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`name (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`pushHandler (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(arrayHelpers: Formik.ArrayHelpers) => React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`removeHandler (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(arrayHelpers: Formik.ArrayHelpers) => React Component`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "пример-использования-6"
    }}>{`Пример использования`}</h3>
    <pre><code parentName="pre" {...{}}>{`<ArrayInput name="array">
    <InputsIterator>
    {
        ({ name }) => <TextInput label="Text 1" name={\`\${name}.text1\`} />
    }
    </InputsIterator>
</ArrayInput>

`}</code></pre>
    <h3 {...{
      "id": "inputsiterator"
    }}>{`InputsIterator`}</h3>
    <p>{`Компонент используется для формирования `}<inlineCode parentName="p">{`layout`}</inlineCode>{` отображения списка записей, с возможностью удаления, добавления, изменения позиции - вверх или вниз.`}</p>
    <h3 {...{
      "id": "props-4"
    }}>{`Props`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Props`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Тип`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`children (required)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(name) => React Component or React.Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`direction (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`vertical or horizontal`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actions (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(arrayHelpers: Formik.ArrayHelpers) => React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actions (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(arrayHelpers: Formik.ArrayHelpers) => React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`addButton (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(arrayHelpers: Formik.ArrayHelpers) => React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`removeButton (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(arrayHelpers: Formik.ArrayHelpers) => React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`moveUpButton (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(arrayHelpers: Formik.ArrayHelpers) => React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`moveDownButton (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(arrayHelpers: Formik.ArrayHelpers) => React Component`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`title (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`showActions (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"add", "remove", "up", "down"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`actionPosition (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`"header", "body"`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`customLayout (optional)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`(record, arrayName) => React Component`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      